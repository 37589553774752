export const SET_TOKEN_NAME = "SET_TOKEN_NAME";
export const SET_TOKEN_SYMBOL = "SET_TOKEN_SYMBOL";
export const SET_DECIMALS = "SET_DECIMALS";
export const SET_TOKEN_OWNER = "SET_TOKEN_OWNER";
export const INIT_TOKEN_OWNER = "INIT_TOKEN_OWNER";
export const SET_TOKEN_TYPE = "SET_TOKEN_TYPE";
export const SET_TOTAL_SUPPLY = "SET_TOTAL_SUPPLY";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_LOADING_ACCOUNTS = "SET_LOADING_ACCOUNTS";
export const SET_APP_STATE = "SET_APP_STATE";
export const SET_CHECKING_PAYING_ACCOUNT_FUNDS = "SET_CHECKING_PAYING_ACCOUNT_FUNDS";
export const SET_PAYING_ACCOUNT_BALANCE = "SET_PAYING_ACCOUNT_BALANCE";
export const SET_INFO_MESSAGE = "SET_INFO_MESSAGE";
export const SET_SERVICE_FEE = "SET_SERVICE_FEE";
export const SET_NETWORK = "SET_NETWORK";
export const SET_CHECKING_NETWORK = "SET_CHECKING_NETWORK";
export const SET_PAYING_ACCOUNT_HAS_INSUFFICIENT_FUNDS = "SET_PAYING_ACCOUNT_HAS_INSUFFICIENT_FUNDS";
export const SET_IS_MOBILE_DEVICE = "SET_IS_MOBILE_DEVICE";
export const SET_WALLET_NEEDS_TO_BE_UNLOCKED = "SET_WALLET_NEEDS_TO_BE_UNLOCKED";
export const SET_PAYING_ACCOUNT = "SET_PAYING_ACCOUNT";

// ICO
export const SET_ICO_CAP = "SET_ICO_CAP";
export const SET_ICO_RATE = "SET_ICO_RATE";
export const SET_ICO_WALLET = "SET_ICO_WALLET";
export const SET_OPENING_TIME = "SET_OPENING_TIME";
export const SET_CLOSING_TIME = "SET_CLOSING_TIME";
export const SET_ICO_GOAL = "SET_ICO_GOAL";
export const SET_ICO_APP_STATE = "SET_ICO_APP_STATE";
export const SET_TOKEN_RECEIPT = "SET_TOKEN_RECEIPT";
export const SET_CROWDSALE_RECEIPT = "SET_CROWDSALE_RECEIPT";

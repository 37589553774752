export default {
  INIT: 1,
  MINING_IN_PROGRESS: 2,
  MINING_CONFIRMED:3,
  MINING_FAILED: 4,
  PENDING_CONFIRMATION: 5,
  INSTALL_WALLET: 6,
  UNLOCK_WALLET: 7,
  HANDLE_PAYMENT: 8
};

import React from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import './css/MetaMaskDemoPlayer.css';

class MetaMaskDemoPlayer extends React.Component {

  render() {
    return (
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={this.props.isVideoOpen}
        onClose={this.props.handleVideoClose}
        onEscapeKeyDown={this.props.handleVideoClose}
      >
       <iframe width="580" height="360" src="//player.bilibili.com/player.html?aid=23810528&bvid=BV1tp411o7L7&cid=39814044&page=1" scrolling="no" border="0" frameBorder="no" frameSpacing="0" allowFullScreen="true"/>
      </Dialog>
    );
  }
}

MetaMaskDemoPlayer.propTypes = {
  isVideoOpen: PropTypes.bool.isRequired,
  handleVideoClose: PropTypes.func.isRequired
};

export default MetaMaskDemoPlayer;

